import React from "react"

import SiteLayout from "../../components/layout/site"

export default () => {
  return (
    <SiteLayout title="Term Of Use">
      <section className="section section-lg">
        <div className="container">
          <h1>Term Of Use</h1>
          <p className="text-right">Last Update 21 November 2016</p>
          <p>
            This Liquid Developer Agreement ("<b>Agreement</b>") is an agreement
            between you and Liquid Group Pte. Ltd. and its related corporations
            (collectively, "<b>Liquid</b>") and applies to your access to and
            use of the Developer's Tools. You must read, agree with and accept
            all the terms and conditions contained in this Agreement. By
            registering for, accessing and/or using the Developer's Tools, you
            acknowledge that you have read, understood and agree to be bound by
            this Agreement. If you do not agree to these terms and conditions,
            you may not access or use the Developer's Tools.
          </p>
          <p>
            This Agreement and any other agreement into which you have entered
            with Liquid (collectively "Liquid Agreements"), apply to your access
            to and use of the Developer's Tools. In the event of any
            inconsistency, this Agreement will control your access to and use of
            the Developer's Tools. We may amend this Agreement at any time by
            posting a revised version on our website. The revised version will
            be effective at the time we post it.{" "}
          </p>
          <p>1. Introduction.</p>
          <p style={{ marginLeft: "24px" }}>
            1.1 LiquidNet Open Payment Platform. The LiquidNet Open Payment
            Platform is a global payments platform open to Developers. Our
            Developer's Tools offer you the ability to build Applications that
            interact with Liquid. The use of Liquid Services, excluding
            Developer Tools and related products and services, in connection
            with a Liquid User Account or a Merchant Account are covered by the
            Liquid Pay Terms of Use and Merchant Agreement as the case may be
            and as may be amended from time to time, or a separate written
            agreement between you and Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            1.2 Liquid Developers. We consider you to be a Developer if you
            access or use the Developer's Tools for any purpose including
            building an Application and/or making an API Call, irrespective of
            whether you use it for your own benefit or as a third party service
            provider. All Developers and API Callers are jointly and severally
            subject to the terms and conditions of this Agreement. Developers
            must have a Liquid Developer Account in good standing.
          </p>
          <p style={{ marginLeft: "24px" }}>
            1.3 App ID and API Credentials. Liquid will provide you with a
            unique confidential identification code, certificate and App ID that
            will enable you to use the Developer's Tools. Liquid may also
            provide you with API Credentials for testing and/or production.
            Liquid may immediately terminate or revoke your App ID and/or API
            Credentials for any reason in Liquid's sole discretion. You may not
            sell, transfer, sublicense, or disclose your App ID, API Credentials
            or other Liquid Developer Account credentials to any third party,
            other than a service provider performing services on your behalf,
            and you agree to notify Liquid immediately of any violation of your
            obligations in this Agreement. You shall not sell or otherwise
            transfer your Application, or permit any API Caller to operate your
            Liquid Developer Account, without the prior approval of Liquid.
            &nbsp;You are liable for all activities performed with your App ID,
            API Credentials or other Liquid Developer Account credentials.
          </p>

          <p> 2. Developer Sandbox Environment.</p>
          <p style={{ marginLeft: "24px" }}>
            2.1 Access. As a Developer, you may test your API Calls in the
            Liquid Developer Sandbox. When testing in the Liquid Developer
            Sandbox, you may only use anonymous, non-live data.
          </p>

          <p style={{ marginLeft: "24px" }}>
            2.2 Usage. You agree that all use of the Liquid Developer Sandbox
            will be in accordance with Liquid's usage policies for Developer's
            Tools which are subject to change from time to time. Liquid may post
            on the LiquidNet Open Payment Platform and/or send an email to you
            with notices of any changes. You agree that Liquid will not be
            liable to you or any third party for any modification or cessation
            of Developer's Tools, including the Liquid Developer Sandbox. All
            accounts and transactions made in the Liquid Developer Sandbox are
            not real transactions and no money is actually transferred. The
            Liquid Developer Sandbox is provided to you on an "as-is" basis and
            Liquid does not guarantee up-time or availability. You agree that
            you will not use any robot, spider, or other automatic device to
            create Liquid Developer Accounts for Liquid Developer Sandbox use
            and you will not take any action that imposes an unreasonable or
            disproportionately large load on our Liquid infrastructure.
          </p>

          <p>3. Live Access.</p>

          <p style={{ marginLeft: "24px" }}>
            Your Application will be given Live Access if the following
            requirements are met:
          </p>

          <p style={{ marginLeft: "24px" }}>
            3.1 Merchant Account. The API Caller or operator of the Application
            has a Merchant Account in good standing. You agree to provide
            information about you and the API Caller or Application operator to
            Liquid before receiving live credentials.
          </p>

          <p style={{ marginLeft: "24px" }}>
            3.2 Application Approval. Your Application must receive prior
            approval from Liquid. You must provide all information and
            documentation we request so we can review and access your
            Application. All approval decisions will be made in Liquid's sole
            discretion. Decisions are based on factors including but not limited
            to whether the Application functions in accordance with your
            representations, complies with the Liquid Developer rules as stated
            in this Agreement, is consistent with the Liquid Agreements and is
            not likely to prove harmful to Liquid, Liquid Users, Merchants and
            partners.
          </p>
          <p>4. APIs.</p>
          <p style={{ marginLeft: "24px" }}>
            4.1 Support. Liquid may provide you with support or modifications
            for the Developer's Tools (collectively, "Support") in its sole
            discretion. Liquid may terminate such Support at any time without
            notice to you. Liquid may change, suspend, or discontinue any aspect
            of the Developer's Tools, at any time, including the availability of
            any Liquid APIs. Liquid may release subsequent versions of APIs, and
            you may be required to use those subsequent versions. Liquid may
            also impose limits on certain features and services or restrict your
            access to parts of or all of the Developer's Tools without notice or
            liability.
          </p>
          <p style={{ marginLeft: "24px" }}>
            4.2 API Caller Certification. If you are accessing an advanced level
            API or more advanced features of a standard level API, you may be
            required to receive API Caller Certification before you are given
            Live Access. We may request, and you agree to provide, additional
            information in connection with API Caller Certification and any
            periodic reviews of your API Caller Certification that we may
            perform from time to time. Liquid does not guarantee that you will
            receive the API Caller Certification for your desired access level,
            and Liquid may revoke API Caller Certification at any time after
            notice to the API Caller. API Caller Certification is not required
            for standard level API Calls.
          </p>
          <p style={{ marginLeft: "24px" }}>
            4.2.1 An advanced level API is an API and/or features of an API that
            Liquid has identified as having a higher level risk to Liquid and
            Liquid Users.
          </p>
          <p style={{ marginLeft: "24px" }}>
            4.2.2 A standard level API is an API and/or features of an API that
            Liquid has identified as having a low risk to Liquid and Liquid
            Users.
          </p>
          <p>5. Working with Liquid as a Developer.</p>
          <p style={{ marginLeft: "24px" }}>
            5.1 General Requirements. If you are a Developer, you must comply
            with the following:
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.1 Have a Liquid Developer Account in good standing.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.2 Not Selectively Offer Liquid. If Liquid is offered as a
            payment method on your website or Application, with your services,
            or on any other property, you must always offer Liquid in connection
            with such service. You may not selectively decide to offer Liquid
            for some transactions but not for other transactions. If your
            Application or website supports Liquid payments, you may selectively
            choose which Liquid payment type you wish to offer in each
            transaction flow, provided only one type of Liquid payment method is
            offered in every flow.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.3 Not engage in any acceptance practices that discriminate
            against or discourage the use of Liquid;
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.4 Not create a method for receiving payments that will result in
            the Recipient avoiding or overpaying Liquid fees, if any;
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.5 Not charge a surcharge to a customer because he/she chooses to
            pay with Liquid. A surcharge is a fee that is charged to the
            transaction based on the payment instrument or mechanism selected by
            the customer. For example, if you charge a fee to accept Liquid
            transactions but not to accept cash transactions and/or another
            payment option, then you are charging a surcharge. You may, however,
            charge Your Users a fee for any value-added services that you may
            provide so long as you charge such fee consistently for such value
            added services. In the event you apply a surcharge, you must inform
            the customer of the requested charge; Liquid has no liability to any
            customer where you have failed to inform the customer of any
            surcharge and you agree that any such liability shall be yours.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.1.6 Not Set Minimums or Maximums. Subject to applicable law, you
            may not set minimum or maximum transaction amounts as a condition of
            accepting Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.2 Checklist. Your Application and your use of the Developer's
            Tools must comply with the following:
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.2.1 Legal Agreements and Related Policies. Your Application and
            your use of the Developer's Tools must comply with the following
            Legal Agreements and policies:
          </p>
          <p style={{ marginLeft: "24px" }}>(a) this Agreement,</p>
          <p style={{ marginLeft: "24px" }}>
            (b) the Merchant Agreement (where applicable);
          </p>
          <p style={{ marginLeft: "24px" }}>(c) the Liquid Pay Terms of Use;</p>
          <p style={{ marginLeft: "24px" }}>
            (d) Acceptable Use Policy, as well as all referenced rules and
            policies under such agreements, all of which are incorporated herein
            by this reference; and
          </p>
          <p style={{ marginLeft: "24px" }}>
            (e) any other agreement you have entered into with Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.2.2 Product Documentation / Specifications. Liquid's
            technical/product/API requirements as communicated in the
            Documentation, integration guidelines and specifications provided or
            made available to you, or as otherwise specified by Liquid from time
            to time.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.2.5 Laws/Regulations/Rules. All applicable laws, regulations, and
            third party payment network rules and regulations (such as Visa,
            MasterCard, American Express, etc.) related to your use of the
            Developer's Tools and Liquid Services, and your provision of the
            Your Liquid-enabled Services, your promotional activities and other
            business activities.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.2.6 Other Requirements. Any other Liquid requirements that we
            provide to you in our sole discretion.
          </p>
          <p style={{ marginLeft: "24px" }}>5.3 Beta Tools and Services.</p>
          <p style={{ marginLeft: "24px" }}>
            5.3.1 Beta Developer's Tools. You acknowledge and agree that: (a)
            any and all Developer's Tools identified as "Beta" ("Beta
            Developer's Tools") may not operate properly, be in final form or
            fully functional; (b) Beta Developer's Tools may contain errors,
            design flaws or other defects; (c) it may not be possible to make
            the Beta Developer's Tools fully functional; (e) the information
            obtained using Beta Developer's Tools may not be accurate; (f) use
            of Beta Developer's Tools may result in unexpected results, loss of
            data or communications or other unpredictable damage or loss; (g)
            Liquid is under no obligation to release a non-Beta version of Beta
            Developer's Tools; and (h) Liquid has the right unilaterally to
            abandon development of any and all Beta Developer's Tools, at any
            time and without any obligation or liability to you.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.3.2 No Warranties. You acknowledge that any and all Beta
            Developer's Tools (a) are Liquid's test products and their
            reliability and accuracy cannot be guaranteed; and (b) must be
            accepted "as is," without any express or implied warranties or
            service levels, including without limitation, the warranties of
            merchantability, fitness for a particular purpose and
            non-infringement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.4 Do Not Circumvent Liquid Policies. You may not create an
            Application or provide Your Liquid-enabled Services that are
            designed to, or have the effect of, circumventing Liquid's rules and
            policies as described in any policy document or legal agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.5 No Avoidance of Account Limits. You may not create, implement or
            use any means of avoiding, modifying, or circumventing Liquid's
            account limits for sending, receiving or withdrawing funds.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.6 Providing Correct Information. All information that you submit
            to Liquid (or Liquid's agent) in connection with your use of the
            Developer's Tools must be true, correct, and complete. This includes
            the following: (i) information you submit directly to Liquid as part
            of Application Review, API Caller Certification, or in response to a
            request from Liquid for information, (ii) information you submit
            through an API Call, and (iii) information you provide to a Liquid
            User, Your User, or other third party in connection with your use of
            the Developer's Tools or provision of your Liquid- enabled Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            5.7 Costs. You are responsible for all costs and expenses related to
            your use of the Developer's Tools including all required
            modifications to your Application. Liquid will not reimburse you for
            any costs or expenses, even if your Application is rejected.
          </p>
          <p>6. Your Relationship With Your Users.</p>
          <p style={{ marginLeft: "24px" }}>
            6.1 Express Consent Required. You must receive Your Users' express
            consent to provide Your Liquid-enabled Services and to ensure
            compliance with all applicable laws, including but not limited to
            the requirements relating to personal data protection laws. In
            addition, you must receive Your Users' express consent for all
            actions you take that are necessary, related to, or resulting from
            your provision of Your Liquid- enabled Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            6.2 Provide Customer Support. You are responsible for the operation
            of your Application, the provision of the Your Liquid-enabled
            Services, and all related customer support, as well as for providing
            customer support for the goods and services you sell.
          </p>
          <p style={{ marginLeft: "24px" }}>
            6.3 Publish a Refund Policy and Privacy Policy. If you are an API
            Caller, you must publish on your website a policy about refunding
            payments. You must also have a published privacy policy that is
            readily accessible through your website, mobile application and the
            store or marketplace where your Application may be downloaded.
          </p>
          <p>
            7. Activities Subject To Additional Restrictions. You must receive
            our Prior Approval if you would like to create/offer an Application
            which is designed for or results in any of the following:
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.1 Expand Global Functionality. Any Application that expands Liquid
            Services beyond Singapore.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.2 Permits Personal Payments or Provide Remittance Services. Any
            Application that allows personal payments originating from Liquid
            Users, or any Application that provides remittance services.
            Remittance services means any service that is: (i) an international
            (cross border) transfer of funds from a sender to a recipient, (ii)
            without an underlying sale or other bona fide commercial purpose for
            the transfer.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.3 Provide Additional Funds-in or Funds-out Methods. Any
            Application that provides additional ways for a Liquid User to put
            funds or value into his/her Liquid Account, or withdraw funds or
            value from his/her Liquid Account.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.4 Provide Banking Services. Any Application which either holds
            money for eventual payment to someone other than the Application's
            API Caller or which offers or provides credit, either directly or as
            a broker or arranger between third parties, or any Application that
            would require licensing as a bank or other financial service
            provider, or as an escrow service in the jurisdiction where the
            service's users reside.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.5 Allow Card-Present Transactions. Any Application that allows a
            Liquid User or customer to make a payment directly using a payment
            card at a physical point of sale or point of interaction, using any
            means available such as a magnetic card reader, mobile phone, EMV
            terminal, automated teller machine (ATM), kiosk, using near field
            technology or radio frequency identification, or any other physical
            location purchase.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.6 Virtual Currency. Any Application that allows for the purchase
            or sale of a virtual currency, which includes vouchers, certificates
            having or indicating a value, any functional equivalent of money, or
            any other representation of value accepted as a means of exchanging
            value between two or more parties. However, non_transferable
            vouchers or certificates issued by a seller and valid only in
            relation to the issuing seller are not a virtual currency.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.7 Perform Activities Requiring Approval under the Acceptable Use
            Policy. Any Application that performs activities or allows for the
            sale of goods or services for which Prior Approval is required by
            Liquid's Acceptable Use Policy.
          </p>
          <p style={{ marginLeft: "24px" }}>
            7.8 Block Liquid Payment Methods. Any Application that blocks a
            Liquid User from selecting a Payment Method in his or her Liquid
            Account.
          </p>
          <p>8. Payment Rules for Your Applications.</p>
          <p style={{ marginLeft: "24px" }}>
            8.1 No Payment Aggregation. This applies to all transaction
            payments.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.1.1 All payments must flow directly from the customer's Liquid
            Account to the Merchant's Liquid Account. Payments may not flow from
            the customer to a third party and then be transferred to the
            Merchant.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.1.2 The Merchant must be the provider of the goods and services of
            record and be identified as such to the customer in the context of
            the sale. Identification may consist of listing the Merchant by its
            full name in the sale terms, on a website used or referenced in the
            sale, or in other representations to the customer at or near the
            time of sale. Identifying an agent or intermediary in the sale
            transaction is not sufficient identification of the actual Merchant.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.2 Merchant Requirements. As a Merchant or seller of goods and
            services, you must do all of the following:
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.2.1 Enter into a direct contractual relationship (agreement) with
            the customer to provide goods or services in exchange for payment or
            other value;
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.2.2 Be the responsible party for completing the transaction to the
            reasonable satisfaction of the customer, including:
          </p>
          <p style={{ marginLeft: "24px" }}>
            (a) Ensure that the products and/or services are delivered to or
            performed for the customer in accordance with your agreement with
            the customer. You do not have to be the actual party delivering the
            products or performing the services, but the customer must have
            recourse against you if the performance or delivery fails to reach
            the customer;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (b) Provide refunds or otherwise resolve customer complaints in
            accordance with your agreement with the customer;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (c) Provide customer support directly or through a third party
            service provider; and
          </p>
          <p style={{ marginLeft: "24px" }}>
            (d) Accept all Transaction Liability and liability under Liquid's
            Acceptable Use Policy.
          </p>
          <p style={{ marginLeft: "24px" }}>
            (e) If you delegate the performance of these Merchant Requirements
            to a third party, you remain responsible to Liquid for their proper
            performance.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.4 Refunds. You may only issue a refund to reverse a prior payment
            transaction. The amount of the refund must be equal to or less than
            the amount of the original payment. Any Liquid payment refund must
            be made to the same Liquid Account from which the payment was sent.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.5 Required Fields in Payment Orders. Your Application must ensure
            that the Payment Order for each payment includes a description field
            to identify the goods or services being sold.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.6 Provide Referring URL. If your Application sells or offers for
            sale goods or services or facilitates sales of goods or services,
            then each Payment Order from your Application must include the URL
            containing a list of all the items available for sale. If your
            Application refers to a website from which terms of sale or further
            information is available, then each Payment Order from your
            Application must provide the URL from the referenced website.
          </p>
          <p style={{ marginLeft: "24px" }}>
            8.7 Take-Down Process. If Liquid determines, in its sole discretion,
            that you are selling goods or services that violate this Agreement,
            the Liquid Pay Terms of Use, the Merchant Agreement, or the
            Acceptable Use Policy, you must, if Liquid requests, promptly stop
            using Liquid Services in relation to the goods or services causing
            the violation.
          </p>
          <p>9. License Rights & Intellectual Property.</p>
          <p style={{ marginLeft: "24px" }}>
            9.1 License to You. Subject to the terms of this Agreement and while
            it remains in effect, Liquid grants you a revocable, non-exclusive,
            non-transferable license to access, integrate and use the
            Developer's Tools into your Application to provide Your
            Liquid-enabled Services. This license grant includes the software
            and all updates, upgrades, new versions and replacement software for
            your personal use only.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.1 You may not rent, lease or otherwise transfer your rights in
            the software to a third party.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.2 You must comply with the implementation and use requirements
            contained in all Documentation accompanying the Liquid Services. If
            you do not comply with Liquid's implementation and use requirements
            you will be liable for all resulting damages suffered by you, Liquid
            and third parties.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.3 Liquid may change or discontinue any APIs upon notice to you.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.4 You agree not to alter, reproduce, adapt, distribute, display,
            publish, reverse engineer, translate, disassemble, decompile or
            otherwise attempt to create any source code that is derived from the
            software.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.5 You acknowledge that all rights, title and interest to
            Liquid's software are owned by Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.1.6 Any third party software application you use on the Liquid
            website is subject to the license you agreed to with the third party
            that provides you with this software. Liquid does not own, control
            nor have any responsibility or liability for any third party
            software application you elect to use on the Liquid website and/or
            in connection with the Liquid Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.2 License to Liquid. You provide Liquid with a paid-up,
            royalty-free, revocable, worldwide, non-exclusive, non- transferable
            license to use your Application for testing, review or other related
            purposes to ensure that your Application complies with this
            Agreement. You may revoke this license at any time by providing a
            written notice to Liquid. If you revoke this license, this Agreement
            will immediately terminate. Upon license revocation, you may request
            removal of your Application from Liquid websites. Liquid will make
            commercially reasonable efforts, as determined in its sole
            discretion, to remove all references and links to your Application
            from Liquid websites. Liquid has no other obligation to delete
            copies of, references to, or links to your Application.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.3 Liquid Marks. Subject to the terms of this Agreement, Liquid
            grants you a revocable, non-exclusive, non-transferable license to
            use the Liquid Marks solely in connection with advertising and
            marketing Your Liquid-enabled Services and in accordance with any
            requirements as may be imposed by Liquid and notified to you from
            time to time.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.4 Your Marks. You grant Liquid a revocable, non-exclusive,
            non-transferable, worldwide, royalty-free license to use your Marks
            to publicize your use of the Developer's Tools and your Application.
            Liquid may do so (i) through press releases, public announcements,
            and other oral communications at conferences, media events, or other
            marketing opportunities; (ii) on the Liquid website or through other
            electronic communications such as emails to Liquid Users,
            newsletters, or in materials that Liquid otherwise makes publicly
            available; and (iii) through any other means of communication to
            promote the use of Liquid, the Developer's Tools, and/or your
            Application.
          </p>
          <p style={{ marginLeft: "24px" }}>9.5 Intellectual Property.</p>
          <p style={{ marginLeft: "24px" }}>
            9.5.1 Liquid retains all rights, title and interest in the
            Intellectual Property embodied in or associated with the Developer's
            Tools (including but not limited to SDKs and APIs), Liquid
            technology and any content derived therefrom.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.5.2 You retain all rights, title and interest in the Intellectual
            Property of those portions of your Application that do not include
            the Developer's Tools or any other Liquid Intellectual Property.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.5.3 You represent and warrant that your Application, including but
            not limited to the name of the Application and all content in your
            Application, does not infringe the Intellectual Property rights of
            Liquid or any third party.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.5.4 There are no implied licenses under this Agreement, and any
            rights not expressly granted are reserved by Liquid. Except as
            licensed expressly herein, this Agreement does not transfer any
            Intellectual Property rights between the parties.
          </p>
          <p style={{ marginLeft: "24px" }}>
            9.6 Competitive or Similar Materials. Notwithstanding the foregoing,
            in no event will Liquid be precluded from developing for itself,
            causing to be developed, acquiring, licensing or developing for
            third parties any products or services that are competitive with
            your Application or Your Liquid-enabled Services, so long as Liquid
            does not infringe your intellectual property rights. Similarly,
            Liquid may create and develop marketing and distribution materials
            about competing products and services offered by Liquid as long as
            those materials do not infringe your intellectual property rights.
          </p>
          <p>10. Prohibited Activities</p>
          <p style={{ marginLeft: "24px" }}>
            10.1 You may not do any of the following:
          </p>
          <p style={{ marginLeft: "24px" }}>
            (a) Collect personal information, financial information, business
            information or any other information directly from Your User, a
            Liquid User, or any other third party on behalf of Liquid. This
            means that any information that you submit to Liquid through an API
            Call, must be customer information that you already have in your
            possession and not customer information that you collect for the
            sole purpose of submitting it to Liquid to allow Your User or a
            third party to create a Liquid Account.
          </p>
          <p style={{ marginLeft: "24px" }}>
            (b) Publicly display Liquid User Information without the consent of
            the Liquid User. For example, you may not have an Application that
            would publicly display a Liquid User's email address or name that
            will identify the Liquid User;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (c) Use the Liquid User Information to send unsolicited
            communications or for any other purpose except the purpose for which
            it was provided;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (d) Sell, lease, rent, transfer, assign or otherwise disclose Liquid
            User Information to a third party;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (e) Use the Liquid User Information to perform competitive,
            comparative or any other type of analysis to develop marketing or
            business strategies directly or indirectly targeting Liquid Users;
            or
          </p>
          <p style={{ marginLeft: "24px" }}>
            (f) Use the Liquid User Information to engage in marketing or other
            sales activities.
          </p>
          <p style={{ marginLeft: "24px" }}>
            10.2 Additional Requirements. Liquid may, in its sole discretion,
            provide you with additional requirements related to your collection,
            use, or storage of Liquid User Information.
          </p>
          <p>11. Confidentiality.</p>
          <p style={{ marginLeft: "24px" }}>
            11.1 Confidential Information Defined. Confidential Information
            means all information that Liquid provides to you under this
            Agreement, including but not limited to the following: (i) the
            Liquid User Information and all other information you receive
            through an API Call or otherwise related to your provision of Your
            Liquid-enabled Services, (ii) the Developer's Tools, API
            Credentials, and all access IDs and passwords, (iii) all information
            disclosed in writing and marked "confidential", proprietary," or
            with a substantially similar marking, (iv) all information disclosed
            orally and identified as confidential at the time of the disclosure,
            and (v) any other information that by its very nature you understand
            or would reasonably be expected to understand to be Liquid's
            confidential information.
          </p>
          <p style={{ marginLeft: "24px" }}>
            11.2 Confidentiality Obligations. You must maintain Liquid's
            Confidential Information in confidence and must not disclose it to
            third parties or use it for any purpose other than as necessary and
            required to perform Your Liquid-enabled Services. In the event that
            Confidential Information is required to be disclosed by a court,
            government agency, regulatory requirement, or similar disclosure
            requirement, you shall immediately notify Liquid and use reasonable
            efforts to obtain confidential treatment or a protection order of
            any disclosed Confidential Information. Your obligations hereunder
            shall survive the termination of this Agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            11.3 Protection of Confidential Information. You acknowledge that
            monetary damages may not be a sufficient remedy for unauthorized use
            or disclosure of Confidential Information and that Liquid will be
            entitled (without waiving any other rights or remedies) to
            injunctive or equitable relief as may be deemed proper by a court of
            competent jurisdiction, without obligation to post any bond.
          </p>
          <p>12. Reserves.</p>
          <p style={{ marginLeft: "24px" }}>
            If we believe there may be a high level of risk associated with your
            Liquid Account once you have taken your application live, we may
            take certain actions in connection with your Account and/or your use
            of the Liquid Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            12.1 Reserves. Liquid, in its sole discretion, may place a Reserve
            on funds held in your Merchant Account when Liquid believes there
            may be a high level of risk associated with your Merchant Account.
            If Liquid places a Reserve on funds in your Merchant Account, they
            will be shown as "pending" in your Liquid balance. If your Merchant
            Account is subject to a Reserve, Liquid will provide you with notice
            specifying the terms of the reserve. The terms may require that a
            certain percentage of the amounts received into your Merchant
            Account are held for a certain period of time, or that a certain
            amount of money is held in reserve. Liquid may change the terms of
            the Reserve at any time by providing you with notice of the new
            terms.
          </p>
          <p style={{ marginLeft: "24px" }}>
            12.2 Additional Actions. We may take other reasonable actions we
            determine are necessary to protect against the risk associated with
            your Merchant Account including requesting additional collateral
            from you such as a letter of credit or a personal guarantee. You
            agree that Liquid may contact your customers, on your behalf, in the
            event that Liquid is investigating a potential fraud.
          </p>
          <p style={{ marginLeft: "24px" }}>
            12.3 Information. To determine the risk associated with your
            Merchant Account, Liquid may request at any time, and you agree to
            provide, any information about your business, operations or
            financial condition. We reserve the right to reassess your
            eligibility for live credentials if your business is materially
            different from the information you provided in your application.
          </p>
          <p>13. Liquid Mobile SDKs and Related APIs.</p>
          <p style={{ marginLeft: "24px" }}>
            13.1 Availability. Live Access for the Liquid Mobile SDKs and/or
            their APIs is available only to eligible Merchant Account holders.
            As such, Section 14 of this Agreement applies only to Merchants who
            have Merchant Accounts and seek Live Access for Applications using
            the Liquid Mobile SDKs and/or their APIs.
          </p>
          <p style={{ marginLeft: "24px" }}>
            13.2 Product Transaction Fees. Payments processed using the Liquid
            Mobile SDKs and/or their APIs are classified as Purchase Payments.
            As such, all applicable transaction fees, including merchant rates,
            shall apply.
          </p>
          <p style={{ marginLeft: "24px" }}>
            13.3 Additional Fees. Any additional fees applicable to Purchase
            Payments, such as Refunds and Chargebacks, will apply to payments
            processed using the Liquid Mobile SDKs and/or their APIs.
          </p>
          <p style={{ marginLeft: "24px" }}>13.4 Payment Terms.</p>
          <p style={{ marginLeft: "24px" }}>
            13.4.1 You agree that Liquid may charge your Merchant Account for
            fees that become due under this Agreement, if you choose this
            payment arrangement. In the event that Liquid is unable to recover
            any fee amount that is due from your Merchant Account, Liquid may
            terminate your use of the Liquid Services within 30 days of the date
            that the fee was due and you will remain obligated to pay Liquid for
            any unpaid amounts.
          </p>
          <p style={{ marginLeft: "24px" }}>
            13.4.2 You agree that Liquid is permitted to charge the credit card
            or bank account (if available) linked to your Merchant Account, if
            you choose this payment arrangement, for fees that become due under
            this Agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            13.4.3 For fees charged on a per transaction basis, the fee amount
            will be deducted from the transaction at the time of the
            transaction.
          </p>
          <p style={{ marginLeft: "24px" }}>
            13.4.4 All fees are non-refundable.
          </p>
          <p>14. Data Security.</p>
          <p style={{ marginLeft: "24px" }}>
            14.1 General. You are fully responsible for the security of data on
            your website and/or Application or otherwise in your possession or
            control. You agree to comply with all applicable laws and rules in
            connection with your collection, security and dissemination of any
            personal, financial, Card, or transaction information (defined as
            "Data") on your website and/or Application.
          </p>
          <p style={{ marginLeft: "24px" }}>14.2 PCI Compliance.</p>
          <p style={{ marginLeft: "24px" }}>
            (a) Liquid Mobile SDKs. You agree that at all times you will follow
            the PCI Mobile Payment Acceptance Security Guidelines.
          </p>
          <p style={{ marginLeft: "24px" }}>
            (b) APIs Used To Process Credit Card Payments. You agree that at all
            times you will comply with the Payment Card Industry Data Security
            Standards (PCI DSS) and the Payment Application Data Security
            Standards (PA DSS), as applicable. You agree to promptly provide us
            with documentation evidencing your compliance with PCI DSS and/or PA
            DSS, if requested by us. You also agree that you will use only PCI
            compliant service providers in connection with the storage, or
            transmission of Card Data defined as a cardholder's account number,
            expiration date, and CVV2. You must not store CVV2 data at any time.
          </p>
          <p style={{ marginLeft: "24px" }}>
            14.3 Data Usage. Unless you receive the consent of your customer,
            you may not retain, track, monitor, store or otherwise use Data
            beyond the scope of the specific transaction. Further, unless you
            get the consent of Liquid and each acquiring bank and/or the
            relevant card companies, as applicable, you agree that you will not
            use nor disclose any card data for any purpose other than to support
            payment for your goods and services. All card data must be
            completely removed from your systems, and any other place where you
            store card data, within 24 hours after you receive an authorization
            decision unless you have received the consent of your customer to
            retain the card data for the sole purpose of processing recurring
            payments. To the extent that card data resides on your systems and
            other storage locations, it should do so only for the express
            purpose of processing your transactions. The following will remain
            the property of Liquid, its appointed Acquirers or the relevant
            issuing banks, as appropriate: (a) all Data and other information
            provided to you by Liquid in relationship to the Liquid Services;
            and (2) all card data.
          </p>
          <p style={{ marginLeft: "24px" }}>
            14.4 Additional Security. You will not give, transfer, assign, sell,
            resell or otherwise dispose of the information and materials
            provided to you to utilize the Liquid Services, including but not
            limited to the Liquid Mobile SDKs and their APIs. You are solely
            responsible for maintaining adequate security and control of any and
            all IDs, passwords, credentials or any other codes that are issued
            to you by Liquid, each Acquiring Bank or the Card Companies.
          </p>
          <p style={{ marginLeft: "24px" }}>
            14.5 Audit. If Liquid believes that a security breach or compromise
            of Data has occurred, Liquid may require you to have a third party
            auditor that is approved by Liquid conduct a security audit of your
            systems and facilities and issue a report to be provided to Liquid,
            the acquiring banks and card companies or issuing banks. In the
            event that you fail to initiate an audit within 10 business days of
            Liquid's request, Liquid may conduct or obtain such an audit at your
            expense.
          </p>
          <p>15. Terms & Termination.</p>
          <p style={{ marginLeft: "24px" }}>
            15.1 Term. This Agreement takes effect on the date that you either:
            (i) accept the terms of this Agreement or (ii) access and/or use the
            Developer's Tools, whichever is the earlier.
          </p>
          <p style={{ marginLeft: "24px" }}>
            15.2 Termination. This Agreement shall remain effective until
            terminated by either party.
          </p>
          <p style={{ marginLeft: "24px" }}>
            15.2.1 Termination by You. You may terminate this Agreement without
            giving reason by providing Liquid with prior written notice to the
            address provided in Legal Terms section of this Agreement. Any other
            termination method will be void and will not result in termination
            of your obligations under this Agreement. Your termination will be
            effective upon Liquid's receipt of notice.
          </p>
          <p style={{ marginLeft: "24px" }}>
            15.2.2 Termination by Liquid. We may terminate this Agreement
            immediately on notice to you, for any of the following reasons:
          </p>
          <p style={{ marginLeft: "24px" }}>
            (a) You (or an Application from which you make an API Call) violate
            one or more or the provisions in this Agreement, the Acceptable Use
            Policy, or any applicable law;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (b) You breach any other term of this Agreement or any other
            agreement between yourself and Liquid and you fail to cure such
            breach within 10 calendar days of receiving notice from Liquid, or
            such other time period specified by Liquid;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (c) You fail to pay Liquid any amounts due under this Agreement or
            any other agreement you have entered into with Liquid;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (d) You do not provide adequate customer support, or your
            Application results in a disproportionally high level of enquiries
            to Liquid customer support;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (e) We close the related Merchant Account for any reason specified
            in the Merchant Agreement or in any other agreement which you have
            entered into with Liquid;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (f) We have reason to believe that your Application, including the
            name of your Application, violates Liquid's or a third party's
            Intellectual Property rights. If you market or otherwise refer to
            your Application as a clone of another application, Liquid may deem
            this evidence of infringement;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (g) Your Application poses a legal, regulatory, or financial risk to
            Liquid in any jurisdiction in which you provide the Your
            Liquid-enabled Services;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (h) You tamper, hack, modify or otherwise corrupt the security or
            functionality of the Liquid Services or the Liquid Developer
            Sandbox;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (i) You or the API Caller becomes insolvent;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (j) You become the subject of any insolvency proceeding, either
            through your own initiative or because an insolvency proceeding is
            begun against you and not dismissed within 30 days;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (k) Any significant portion of your assets is attached, seized, or
            levied on, and the attachment, seizure or levy is not removed within
            10 days;
          </p>
          <p style={{ marginLeft: "24px" }}>
            (l) Any significant portion of your assets comes into the possession
            or control of a receiver, liquidator, trustee or similar
            administrator; or
          </p>
          <p style={{ marginLeft: "24px" }}>
            (m) You enter into or propose any composition or arrangement with
            your creditors (or any class of your creditors) concerning your
            debts.
          </p>
          <p style={{ marginLeft: "24px" }}>
            15.2.3 Effect of Termination. Upon termination of this Agreement,
            (i) all rights and licenses under this Agreement shall immediately
            terminate; (ii) you must stop offering and providing the Your
            Liquid-enabled Services; (iii) you must destroy all Liquid
            Confidential Information and Liquid User Information within 7
            calendar days of termination, and upon Liquid's request provide
            proof of such destruction within 7 calendar days of Liquid's request
            for proof; and (iv) you will remain liable for any amounts due or
            other liability under this Agreement.
          </p>
          <p>16. Other Miscellaneous Terms</p>
          <p style={{ marginLeft: "24px" }}>
            16.1 Choice of law and jurisdiction. his Agreement shall be governed
            by and construed in accordance with the laws of Singapore. The
            courts of Singapore shall have exclusive jurisdiction over all
            disputes arising in relation to this Agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>16.2 Notice.</p>
          <p style={{ marginLeft: "24px" }}>
            16.2.1 Notice to You. You agree that Liquid may provide notice to
            you by posting it on our website, emailing it to the email address
            associated with your Liquid Developer Account, or mailing it to the
            street address listed in your Liquid Developer Account. Notices sent
            to you by email are considered to be received by you within 24 hours
            of the time we send the email unless we receive notice the email was
            not delivered. Notices sent to you by mail are considered received
            by you within 3 Business Days of the date we send the notice unless
            it is returned to us.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.2.2 Notices to Liquid. Notice to Liquid must be sent by postal
            mail to Liquid Group Pte. Ltd. at 1 Fusionopolis View, #07-02,
            Sandcrawler, Singapore 138577 or such other address as may be
            notified to you.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3 Indemnification / Limitation of Liability / Disclaimers.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3.1 Indemnification. You agree to defend, indemnify and hold
            harmless Liquid, its officers, directors and employees from any
            claim, suit, or demand (including legal fees) made or incurred by
            any third party that is based on or arising out of your: (i) breach
            of this Agreement, (ii) violation of any law, regulation, or rule;
            (ii) infringement of any third party's intellectual property rights,
            (iii) use of the Developer's Tools, Application or technology;
            and/or (iv) other actions or omissions resulting in Transaction
            Liability or other liability to Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3.2 Limitations of Liability. IN NO EVENT SHALL LIQUID, ITS
            RELATED CORPORATIONS, PERSONS WHO ACT ON LIQUID'S BEHALF, AND/OR THE
            PERSONS LIQUID ENTERS INTO CONTRACTS WITH BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, LIQUID
            SERVICES, WEBSITES, DEVELOPER'S TOOLS OR OTHER ACTIONS OR OMISSIONS
            INCLUDING:
          </p>
          <p style={{ marginLeft: "24px" }}>
            (a) ANY LOSS OF PROFITS, GOODWILL, BUSINESS, CONTRACTS, REVENUE OR
            ANTICIPATED SAVINGS EVEN IF LIQUID IS ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, LOSS OF PROFITS, GOODWILL, BUSINESS, CONTRACTS,
            REVENUE OR ANTICIPATED SAVINGS; OR
          </p>
          <p style={{ marginLeft: "24px" }}>
            (b) ANY LOSS OR CORRUPTION OF DATA; OR
          </p>
          <p style={{ marginLeft: "24px" }}>
            (c) ANY LOSS OR DAMAGE WHATSOEVER WHICH DOES NOT STEM DIRECTLY FROM
            LIQUID'S BREACH OF THIS AGREEMENT; OR
          </p>
          <p style={{ marginLeft: "24px" }}>
            (d) TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANY LOSS OR DAMAGE
            WHATSOEVER WHICH IS IN EXCESS OF THAT WHICH WAS CAUSED AS A DIRECT
            RESULT OF OUR BREACH OF THIS AGREEMENT (WHETHER OR NOT YOU ARE ABLE
            TO PROVE SUCH LOSS OR DAMAGE); OR
          </p>
          <p style={{ marginLeft: "24px" }}>
            (e) AN AMOUNT EXCEEDING THE AMOUNT OF THE DIRECT DAMAGES DIRECTLY
            CAUSED BY OUR BREACH OF THIS AGREEMENT, NEGLIGENCE, STRICT LIABIITY
            OR OTHER LEGAL OR EQUITABLE THEORY.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3.3 DISCLAIMER OF WARRANTIES. EXCEPT AS EXPRESSLY SET FORTH IN
            THIS AGREEMENT, LIQUID DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES,
            EXPRESS OR IMPLIED, REGARDING THE LIQUID WEBSITE, SERVICES AND
            DEVELOPER'S TOOLS, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTY
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT. THE LIQUID WEBSITE, LIQUID SERVICES, AND
            DEVELOPER'S TOOLS ARE PROVIDED "AS-IS" AND LIQUID MAKES NO WARRANTY
            THAT THE LIQUIDNET OPEN PAYMENT PLATFORM, SERVICES, AND DEVELOPER'S
            TOOLS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. LIQUID
            DOES NOT HAVE CONTROL OF, OR LIABILITY FOR, THE PRODUCTS OR SERVICES
            PURCHASED USING THE LIQUID SERVICES, OR GUARANTEE THE IDENTITY OF
            ANY LIQUID USER.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3.4 Security and Stability. You acknowledge that it is in the
            best interests of both you and Liquid that Liquid maintains a secure
            and stable environment. To that end, Liquid reserves the right to
            change the method of access to the Developer's Tools at any time.
            You also agree that, in the event of degradation or instability of
            Liquid's system or an emergency, Liquid may, in its sole discretion,
            temporarily suspend your access to or ability to use the Developer's
            Tools in order to counteract security threats or to and protect the
            operational stability and security of the Liquid system.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.3.5 Uptime. Liquid does not guarantee any minimum uptime for the
            LiquidNet Open Payment Platform, Developer's Tools, and/or Liquid
            Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.4 Transaction Liability. The Merchant Agreement determines your
            Transaction Liability.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.5 Service Providers. You are responsible for ensuring that any
            third party that you engage or otherwise allow to exercise your
            rights or perform your obligations under this Agreement complies
            with this Agreement. You will be responsible for any breach of this
            Agreement or violation of applicable law by any such third party, or
            where that breach or violation is done by someone acting with your
            authority or by agreement with you. You agree to supervise any third
            party or person acting on your behalf sufficiently to prevent
            breaches of this Agreement or violations of law.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.6 Taxes. It is your responsibility to determine what, if any,
            taxes apply to the payments you make or receive, and it is your
            responsibility to collect, report and remit the correct tax to the
            appropriate tax authority. Liquid is not responsible for determining
            whether taxes apply to your transaction, or for collecting,
            reporting or remitting any taxes arising from any transaction.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.7 Your Relationship with Liquid. You and Liquid are independent
            contractors. This Agreement does not create or imply any
            partnership, agency, or joint venture.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.8 Assignment. You may not transfer or assign this Agreement
            without Liquid's Prior Approval. Liquid reserves the right to
            transfer or assign this Agreement or any right or obligation under
            this Agreement by providing you with notice of such transfer or
            assignment.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.9 No Waiver. Our failure to enforce any provision of this
            Agreement will not be deemed a waiver of our ability to enforce the
            same provision of the Agreement at a future date.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.10 Severability. If any provision of this Agreement is found
            illegal or unenforceable, that provision will be limited or
            eliminated to the minimum extent necessary so that this Agreement
            will otherwise remain in full force and effect.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.11 Force Majeure. Liquid is not responsible for any failure to
            perform its obligations under this Agreement if such failure is
            caused by acts of God, war, strikes, revolutions, lack or failure of
            transportation facilities, laws or governmental regulations or other
            causes that are beyond Liquid's reasonable control. In the event of
            such a failure, Liquid's obligations shall be suspended until Liquid
            is able to perform.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.12 Complete Agreement. The Liquid Agreements, including any
            policies referenced therein set forth the entire understanding
            between you and Liquid with respect to the access and use of the
            Developer's Tools.
          </p>
          <p style={{ marginLeft: "24px" }}>
            16.13 No Third Party Rights. A person who is not a party to this
            Agreement does not have any rights under the Contracts (Rights of
            Third Parties) Act of Singapore.
          </p>
          <p>17. Definitions.</p>
          <p style={{ marginLeft: "24px" }}>
            Capitalized terms used in this Agreement and not otherwise defined
            in this Section have the meaning assigned to them in the Liquid Pay
            Terms of Use or Merchant Agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "API" means each of Liquid's proprietary application programming
            interfaces, which are used by Developers to interface with Liquid
            Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "API Call" means a call from an Application via an API to interact
            with the Liquid Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "API Caller" means the person or persons making the API Call to the
            Liquid Services in Live Access. The API Caller may be the
            Application operator or Developer. In a case where a person makes an
            API call on behalf of another person, both persons are API Callers,
            jointly and severally.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "API Credentials" means your API username and password, and either
            an API signature or an API certificate.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Application" means a third party software application that contains
            embedded calls to a Liquid API. An Application may run on a
            web-based or non web-based platform.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "App ID" means the unique identifier assigned to an Application
            Version. This is required to access the API in the Liquid Developer
            Sandbox or Live Access.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Application Review" means the process through which Liquid, or a
            third party designated by Liquid, reviews your Application and any
            information provided by you to decide whether to approve your
            Application for Live Access.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Branding Requirements" means Liquid's then current logo usage and
            branding requirements provided or made available by Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Database" means Liquid's database containing Liquid Users'
            information that you query through the Developer's Tools in order to
            perform the Liquid-enabled Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Developer" means anyone who accesses or uses the Developer's Tools,
            and/or agrees to this Agreement.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Developer's Tools" means the APIs, API Credentials, SDKs,
            Documentation, Liquid Developer Sandbox, Live Access, developer
            websites and portals, technical support, and all other tools,
            services, content and information made available to you by Liquid
            through the LiquidNet Open Payment Platform or such other Liquid
            websites and/or webpages, or any other means.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Documentation" means all written information provided or made
            available to you by Liquid including information related to the
            Liquid Services, Developer's Tools, Developer's guides, and
            reference guides. Documentation may be provided on the LiquidNet
            Open Payment Platform or such other Liquid websites and/or webpages.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Information Security Requirements" means Liquid's then current
            information security requirements provided or made available by
            Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Intellectual Property" means any and all intellectual property or
            proprietary rights under any jurisdiction including without
            limitation (i) Marks, and all goodwill associated therewith and
            symbolized thereby; (ii) inventions, discoveries and ideas, whether
            patentable or not, and all patents, registrations, and applications
            thereof; (iii) published and unpublished works of authorship,
            whether copyrightable or not (including without limitation Databases
            and other compilations of information), copyrights therein and
            thereto, and registrations and applications thereof; (iv) trade
            secrets,(v) all moral rights in the foregoing (that is, the right to
            claim authorship of or object to the modification of any work); and
            (vi) all applications, renewals, extensions, restorations and
            reinstatements of the foregoing.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Liquid Developer Account" means a Liquid account assigned by Liquid
            to a Developer.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Liquid Developer Sandbox" means the Liquid test environment and any
            related products and services as may be provided by Liquid from time
            to time.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Liquid User" means any person who accesses a Liquid website, uses
            the Liquid Services, and/or any person or entity that has agreed to
            the Liquid Pay Terms of Use.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Liquid User Information" means any information related to a Liquid
            User or their Liquid Account that you receive directly or indirectly
            from: (i) a Liquid User in connection with your provision or
            operation of Your Liquid-enabled Services; or (ii) from Liquid
            through an API Call or by any other means.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Live Access" means the live Liquid production environment which
            includes live access to the Liquid Services and Databases.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Marks" means the trademarks including registered and common law
            trademarks, trade names, service marks, logos, domain names and
            designations of a party or its products and services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Merchant" means a provider of goods and services that has entered
            into a Merchant Agreement with Liquid to accept payment by Liquid
            from its customers.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Merchant Account" means an account of a participating Liquid
            merchant who has entered into a valid Merchant Agreement with
            Liquid.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Merchant Agreement" means a merchant agreement entered into between
            Liquid and a Merchant.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Payment Order" means the instruction given to Liquid to perform a
            payment.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "SDK" means each of Liquid's downloadable software developer kits
            including a package of libraries, applications, associated
            Documentation, and sample code.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Transaction Liability" means all liability related to receiving a
            payment, including reversals, chargebacks, unauthorized
            transactions, fraud, claims, fees, fines, penalties and other
            liability incurred by Liquid, a Liquid User, or a third party.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Your Liquid-enabled Services" means the products or services that
            you offer and provide to Your Users using the Developer's Tools,
            Liquid Services, or any Liquid User Information. Your Liquid-enabled
            Services must be provided in accordance with the terms and
            conditions of this Agreement, and only upon receipt of the consent
            of a Liquid User.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Your User Information" means any customer information that you
            collect directly from Your Users without the use of the Developer's
            Tools or other Liquid Services, and for purposes other than
            providing the Your Liquid-enabled Services.
          </p>
          <p style={{ marginLeft: "24px" }}>
            "Your Users" mean customers that have entered into a relationship
            with you to purchase goods or services, or end-users of your
            Application, product, services or other offering. Your Users that
            have a Liquid Account are also Liquid Users.
          </p>
        </div>
      </section>
    </SiteLayout>
  )
}
